<template>
  <div class="modal fade" 
    :id="id" 
    :data-bs-backdrop="backdrop" 
    :data-bs-keyboard="keyboard" 
    tabindex="-1" 
    aria-labelledby="ModalLabel" 
    aria-hidden="true"
  >
    <div :class="`modal-dialog ${size} ${verticalCentered ? 'modal-dialog-centered' : ''} ${fullscreen}`">
      <div class="modal-content">
        <div class="modal-header">
          <h6 class="modal-title" id="ModalLabel">
            <slot name="modalTitle"></slot>
          </h6>
          <button type="button" class="btn-close text-dark modalClose" data-bs-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <slot name="modalBody"></slot>
        </div>
        <div class="modal-footer">
          <slot name="modalFooter"></slot>

          <button 
            type="button" 
            class="btn btn-light my-0 me-2" 
            data-bs-dismiss="modal"
            v-if="defaultFooterBtn"
          >{{cancelText}}</button>
          
          <Buttons
            v-if="defaultFooterBtn"
            background="success"
            :text="submitText"
            :defaultSpacing="true"
            classOverride="m-0"
            @handleClick="this.$emit('handleSubmit', $event)"
            :isLoaded="!alerts.modal.submitting"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { mapState } from 'vuex';
  export default {
    name: 'Modal',
    props: {
      id: String,
      size: {
        type: String,
        default: '',
      },
      verticalCentered: {
        type: Boolean,
        default: false,
      },
      fullscreen: {
        type: String,
        default: '',
      },
      backdrop: {
        type: Boolean || String,
        default: true,
      },
      keyboard: {
        type: Boolean || String,
        default: false,
      },
      submitText: {
        type: String,
        default: 'Submit',
      },
      cancelText: {
        type: String,
        default: 'Cancel',
      },
    },

    data() {
      return {
        defaultFooterBtn: true,
      }
    },

    computed: {
      ...mapState(['alerts']),
    },
    mounted() {
      if (this.$slots.modalFooter) {
        this.defaultFooterBtn = false;
      }

      let self = this;
      const modalElement = document.getElementById(this.id);

      // Add event listener to open modal
      modalElement.addEventListener('shown.bs.modal', function (event) {
        self.$emit('handleOpened', event);
      })

      // Add event listener to close modal
      modalElement.addEventListener('hidden.bs.modal', function (event) {
        self.$emit('handleClose', event);
      });
    },
  };
</script>
<style></style>