import { pathResolver } from '@/functions/pathResolver.js';

let app = pathResolver.primaryPaths('app');
let tenant = pathResolver.primaryPaths('tenant');

export default {
  namespaced: true,
  state: {
    tab: '',
    page: {
      list: 'registers',
      form: false,
      autosave: false
    },
    data: {},
    validations: {},
  },
  actions: {
    async getColumns ({}) {
      return await new Promise((resolve, reject) => {
        axios
          .get(`/${app}/${tenant}/states/payroll_registers`, {
            params: { except: ['id', 'created_at', 'updated_at', 'deleted_at'] }
          })
          .then(async response => {
            resolve(response.data);
          })
          .catch(function (error) {
            if (error.response !== 200) {
              console.log(`ERROR: response is ${error.response}`);
            }
            console.log(error.response);
            reject();
          });
      });
    },

    async setColumns ({ dispatch, state }) {
      const columns = await dispatch('getColumns');
      state.data = columns;
    },

    async setDataToState ({ dispatch, state }, data) {
      if (typeof data !== 'undefined') {
        await dispatch('setColumns').then(() => {
          state.data = data;
        });
      }
    },

    store ({ state, rootState }) {
      rootState.alerts.modal.submitting = true;
      const data = state.data;
      state.validations = {};
      
      return axios
        .post(`/${app}/${tenant}/registers/store`, data)
        .then(response => {
          if (response.data.status === 'invalid') {
            rootState.alerts.modal.submitting = false;
            state.validations = response.data.errors;
            setTimeout(function () {
              rootState.alerts.invalid = true;
            }, 500);

            return response.data;
          } else if (response.data.status === 'process-error') {
            rootState.alerts.modal.submitting = false;
            setTimeout(function () {
              rootState.alerts.modal.customMessage = [true, response.data.message]
            }, 500);

            return response.data;
          }

          return response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
}