<template>
  <button
    type="button"
    :class="`btn btn-${background} ${textColor ? 'text-' + textColor : ''} ${
      !defaultSpacing ? 'm-0' : ''
    } ${!noIcon && !defaultSpacing ? 'me-3 py-1 px-3' : ''} ${
      !action && !defaultSpacing ? 'py-2' : ''
    } ${!text ? 'shadow-none' : ''} ${classOverride ?? ''} ${disabled ? 'not-allowed' : ''} position-relative`"
    data-bs-toggle="tooltip"
    data-bs-placement="bottom"
    :title="tooltip ? tooltip : action ? actions[action].text : ''"
    @click="handleClick"
    :disabled="disabled"
  >
    <div class="d-flex align-items-center justify-content-center">
      <span v-if="!this.$slots.icon && !noIcon">
        <i :class="`${actions[action]?.icon} ${text ? 'fs-6' : 'fs-4'}`"></i>
      </span>
      <slot name="icon" v-if="this.$slots.icon && !noIcon"></slot>
      <span class="px-2" v-if="text">{{ text }}</span>
    </div>

    <div
      v-if="!isLoaded"
      class="position-absolute w-100 h-100 top-0 start-0 d-flex align-items-center justify-content-center btn-spinner-bg"
    >
      <div class="spinner-border spinner-border-sm" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </button>
</template>
<script>
export default {
  name: 'Buttons',
  props: {
    defaultSpacing: {
      type: Boolean,
      default: false,
    },
    classOverride: String,
    action: String,
    noIcon: {
      type: Boolean,
      default: false,
    },
    background: {
      type: String,
      default: 'default',
    },
    textColor: String,
    text: {
      type: String,
      default: null,
    },
    tooltip: String,
    isLoaded: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      actions: {
        create: {
          text: 'Create',
          icon: 'bi bi-file-earmark-plus-fill',
        },
        edit: {
          text: 'Edit',
          icon: 'bi-pencil-square',
        },
        delete: {
          text: 'Delete',
          icon: 'bi bi-trash',
        },
        view: {
          text: 'View',
          icon: 'bi bi-eye',
        },
        export: {
          text: 'Export',
          icon: 'bi bi-file-earmark-arrow-down-fill',
        },
      },
    };
  },
  methods: {
    handleClick() {
      this.$emit('handleClick');
    },
  },
};
</script>
<style>
.btn-spinner-bg {
  background-color: rgba(255, 255, 255, 0.8);
}
.not-allowed {
  cursor: not-allowed !important;
}
</style>
