import axiosRequest from '@/functions/axiosRequests.js';
import { pathResolver } from '@/functions/pathResolver.js';
import { formatter } from '@/functions/formatter';
import { processors } from '../../../functions/processors';

let app = pathResolver.primaryPaths('app');
let tenant = pathResolver.primaryPaths('tenant');

export default {
  namespaced: true,

  state: {
    tab: '',
    page: {
      list: 'payroll',
      form: false,
      autosave: false
    },
    data: {
      parent: {},
      lines: [],
    },
    parentValidations: [],
    lineValidations: [],
    accountOptions: []
  },

  mutations: {
    setLine (state, { line }) {
      state.data.lines.push(line);
    },

    removeLine (state, { index }) {
      state.data.lines.splice(index, 1);
    }
  },

  actions: {
    async getParentColumns ({}) {
      return await new Promise((resolve, reject) => {
        axios
          .get(`/${app}/${tenant}/states/payrolls`, {
            params: { except: ['id', 'created_at', 'updated_at', 'deleted_at'] }
          })
          .then(async response => {
            resolve(response.data);
          })
          .catch(function (error) {
            if (error.response !== 200) {
              console.log(`ERROR: response is ${error.response}`);
            }
            console.log(error.response);
            reject();
          });
      });
    },

    async getLinesColumns ({}) {
      return await new Promise((resolve, reject) => {
        axios
          .get(`/${app}/${tenant}/states/payroll_lines`, {
            params: { except: ['id', 'created_at', 'updated_at', 'deleted_at'] }
          })
          .then(async response => {
            resolve(response.data);
          })
          .catch(function (error) {
            if (error.response !== 200) {
              console.log(`ERROR: response is ${error.response}`);
            }
            console.log(error.response);
            reject();
          });
      });
    },

    async setParentColumns ({ dispatch, state }) {
      const parent = await dispatch('getParentColumns');
      state.data.parent = parent;
    },

    async setLineColumns ({ dispatch, commit }) {
      const line = await dispatch('getLinesColumns');
      commit('setLine', { line });
    },

    removeLineColumns ({ dispatch, commit }, index) {
      commit('removeLine', { index });
      dispatch('setGrandTotals');
    },

    async setDataToState ({ dispatch, state, rootState }, data) {
      if (typeof data !== 'undefined') {
        const parent = _.omit(data, 'lines');

        await dispatch('setParentColumns').then(() => {
          state.data.parent = parent;
        });

        const lines = data.lines;

        const accounts = await dispatch('getFullAccounts');
        state.accountOptions = state.accountOptions.concat(accounts);

        for (let i = 0; i < 5; i++) {
          dispatch('setLineColumns').then(() => {
            state.data.lines = lines;
            state.data.lines[i].accountOptions = state.accountOptions;

            state.accountOptions = _.reject(
              state.accountOptions,
              account => account.id === state.data.lines[i].account_id
            );
          });
        }

        // dispatch('setGrandTotals')
      }
    },

    setGrandTotals ({ state }, type) {
      const debits = _.sumBy(
        state.data.lines,
        line => _.toNumber(line.debit) || 0
      );
      const credits = _.sumBy(
        state.data.lines,
        line => _.toNumber(line.credit) || 0
      );

      state.data.parent.total_debit = formatter.currency(debits);
      state.data.parent.total_credit = formatter.currency(credits);
    },

    store ({ state, rootState }) {
      return axios
        .post(`/${app}/${tenant}/payroll/store`, {
          parent: state.data.parent,
          lines: state.data.lines
        })
        .then(response => {
          setTimeout(function () {
            rootState.alerts.saving = false;
          }, 500);

          if (response.data.message === 'invalid') {
            rootState.alerts.submitting = false;

            const validation = processors.getTabledFormValidation(response);
            state.parentValidations = validation.parent;
            state.lineValidations = validation.lines;

            rootState.alerts.saving = false;

            setTimeout(function () {
              rootState.alerts.invalid = true;
            }, 500);

            return response.data;
          }

          return response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    update ({ state, rootState }, id) {
      return axiosRequest
        .put(`/${app}/${tenant}/payroll/${id}`, {
          parent: state.data.parent,
          lines: state.data.lines
        })
        .then(response => {
          setTimeout(function () {
            rootState.alerts.saving = false;
          }, 500);

          if (response.message === 'invalid') {
            rootState.alerts.submitting = false;

            const validation = processors.getTabledFormValidation(response);
            state.parentValidations = validation.parent;
            state.lineValidations = validation.lines;

            rootState.alerts.saving = false;

            setTimeout(function () {
              rootState.alerts.invalid = true;
            }, 500);

            return response;
          }

          return response;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    delete ({ commit, dispatch, state }) {
      return axiosRequest.delete(
        `/${app}/${tenant}/payroll/${state.data.parent.id}`
      );
    },

    getFullAccounts () {
      const codes = [
        'basic_pay',
        'basic_pay_mwe',
        'ot_pay',
        'ot_pay_mwe',
        'holiday_pay',
        'holiday_pay_mwe',
        'hazard_pay',
        'hazard_pay_mwe',
        'thirteenth_pay',
        'thirteenth_pay_mwe',
        'sick_leave_benefits',
        'sick_leave_benefits_mwe',
        'sss_employee_contribution_expense',
        'sss_employee_contribution_expense_mwe',
        'sss_employer_contribution',
        'sss_employer_contribution_mwe',
        'philhealth_employer_contribution',
        'philhealth_employer_contribution_mwe',
        'pagibig_employee_contribution',
        'pagibig_employer_contribution',
        'pagibig_employer_contribution_mwe'
      ];

      const accountsData = processors.getAttributes().sharedResources.accounts;
      const sourceTypes = codes.map(type => {
        return { ['account_codes']: type };
      });
      const types = sourceTypes.map(type => type.account_codes);
      const result = _.filter(accountsData, account =>
        types.includes(account.account_code)
      );

      return _.sortBy(_.flatten(result), [
        function (account) {
          return account.id;
        }
      ]).map(res => {
        return {
          id: res.id,
          value: `${res.code ? res.code + '-' : ''} ${res.name}`
        };
      });
    }
  }
};
