export const formatter = {
  currency (value) {
    const formattedValue = Number(value || 0)
      .toFixed(2)
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    return formattedValue;
  },

  displayDate (dateString) {
    return moment(dateString).format('DD MMM YYYY');
  },

  snakeToTitle(inputString) {
    let words = inputString.split('_');
    
    for (let i = 0; i < words.length; i++) {
      words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
    }
    
    let resultString = words.join(' ');
  
    return resultString;
  },

  toKebabCase(str) {
    return str
        .toLowerCase() // Convert to lowercase
        .replace(/\s+/g, '-') // Replace spaces with hyphens
        .replace(/_/g, '-') // Replace underscores with hyphens
        .replace(/[^\w\-]+/g, '') // Remove special characters
        .replace(/\-\-+/g, '-') // Replace multiple hyphens with a single hyphen
        .replace(/^-+/, '') // Trim hyphens from the start
        .replace(/-+$/, ''); // Trim hyphens from the end
  },

  toSnakeCase(str) {
    return str
        .toLowerCase() // Convert to lowercase
        .replace(/\s+/g, '_') // Replace spaces with underscores
        .replace(/-/g, '_') // Replace hyphens with underscores
        .replace(/[^\w_]+/g, '') // Remove special characters
        .replace(/__+/g, '_') // Replace multiple underscores with a single underscore
        .replace(/^_+/, '') // Trim underscores from the start
        .replace(/_+$/, ''); // Trim underscores from the end
  },

  getMonthsSelection () {
    return [
      { id: 1, value: 'January' },
      { id: 2, value: 'February' },
      { id: 3, value: 'March' },
      { id: 4, value: 'April' },
      { id: 5, value: 'May' },
      { id: 6, value: 'June' },
      { id: 7, value: 'July' },
      { id: 8, value: 'August' },
      { id: 9, value: 'September' },
      { id: 10, value: 'October' },
      { id: 11, value: 'November' },
      { id: 12, value: 'December' }
    ];
  },

  getYearsSelection (hasFuture) {
    let years = [];
    const currentYear = moment().format('YYYY');

    for (let i = 0; i <= 5; i++) {
      years.push({ id: currentYear - i, value: String(currentYear - i) });
    }

    if (hasFuture) {
      for (let i = 1; i <= 5; i++) {
        years.push({
          id: parseInt(currentYear) + i,
          value: String(parseInt(currentYear) + i)
        });
      }
    }

    return _.sortBy(years, ['id']).reverse();
  },

  fullName (firstName, middleName = '', lastName) {
    if (middleName) {
      return `${firstName} ${middleName} ${lastName}`;
    } else {
      return `${firstName} ${lastName}`;
    }
  },

  buildQueryString (params) {
    return Object.keys(params)
      .map(
        key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
      )
      .join('&');
  },

  explodeQueryString (queryString) {
    const params = new URLSearchParams(queryString);
    let qObj = {};

    for (var pair of params.entries()) {
      qObj[pair[0]] = pair[1];
    }

    return qObj;
  }
};
